import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"

const JASM = () => (
	<Layout>
		<Container className="mt-5">
			<h1 className="text-center">JASM</h1>
			<Row className="mt-5 justify-content-center">
				<Col xs={10} md={8}>
					<p>
						Dear ICA-conference participants. Welcome to JASM (pronounced Shazam).
					</p>
					<p>
						JASM stands for
						Julia+Activated Sludge Model. Currently ASM1 is implemented in <a href="https://julialang.org/">Julia</a>.
						You find the repository on gitlab. Here is <a href="https://gitlab.com/Viraja/automatic_feature_extraction">JASM</a>.
					</p>
					<p>
						Please test, report bugs, and contact me for collaboration.
						Contact: myschneider(at)isi.imi.i.u-tokyo.ac.jp
					</p>
				</Col>
			</Row>
		</Container>
	</Layout>
)

export default JASM
